[
    {"mensagem": "SMH / SMDH", "autor": "Shaking My Head / Shaking My Damn Head"},
    {"mensagem": "H/T", "autor": "Hat Tip"},
    {"mensagem": "TBH", "autor": "To Be Honest"},
    {"mensagem": "IMO", "autor": "In My Opionion."},
    {"mensagem": "ICYMI", "autor": "In Case You Missed It."},
    {"mensagem": "BFN", "autor" : "Bye For Now"},
    {"mensagem": "BTW", "autor": "By The Way"},
    {"mensagem": "FOMO", "autor" : "Fear Of Missing Out"},
    {"mensagem": "HAND", "autor" : "Have A Nice Day"},
    {"mensagem": "IDK", "autor" : "I Don't Know"},
    {"mensagem": "NSFW", "autor" : "Not Safe For Work"},
    {"mensagem": "YOLO", "autor" : "You Only Live Once"},
    {"mensagem": "YOYO", "autor" : "You're On Your Own"},
    {"mensagem": "TLDR", "autor" : "Too Long; Didn't Read"},
    {"mensagem": "Hobnob", "autor": "Socialize with people of an artificially higher status"},
    {"mensagem": "Chill", "autor": "Relax"},
    {"mensagem": "Popo", "autor": "Police"},
    {"mensagem": "Fuzz", "autor": "Police"},
    {"mensagem": "Airhead", "autor": "Dumb person"},
    {"mensagem": "Cringe", "autor": "Really embarrassing"},
    {"mensagem": "Copium", "autor": "Fake drug to help twitch users deal with losing"},
    {"mensagem": "Simp", "autor": "Person who does too much for who they like"},
    {"mensagem": "Cram", "autor": "Study like crazy"},
    {"mensagem": "Crash", "autor": "Sleep or Nap"},
    {"mensagem": "DMs", "autor": "Direct messages"},
    {"mensagem": "Dead", "autor": "Establishment is slow or Person did a bad thing"},
    {"mensagem": "Steez", "autor": "Effortless style"},
    {"mensagem": "Spox", "autor": "Spokesperson"},
    {"mensagem": "Sawbuck", "autor": "$10"},
    {"mensagem": "A-Game", "autor": "Best effort"},
    {"mensagem": "Oops!", "autor": "When someone messes up"},
    {"mensagem": "Dude", "autor": "A guy"},
    {"mensagem": "Nuts", "autor": "Unbelievable or crazy"},
    {"mensagem": "Dope!", "autor": "Wonderful"},
    {"mensagem": "Yuppie", "autor": "Young urban professional, white-collar worker"},
    {"mensagem": "White-Collar", "autor": "High-salary job that does not involve manual labor, such as accountants and lawyers"},
    {"mensagem": "Blue-Collar", "autor": "Jobs focused on manual labor, such as janitors and construction"},
    {"mensagem": "Cray", "autor": "Crazy"},
    {"mensagem": "Woke", "autor": "Aware of potential injustices"},
    {"mensagem": "Poppin", "autor": "Establishment is busy"},
    {"mensagem": "Lit", "autor": "Drunk or superb"},
    {"mensagem": "Swole", "autor": "Very muscular"},
    {"mensagem": "Drip", "autor": "Fashionable"},
    {"mensagem": "Drownin", "autor": "Unfashionable"},
    {"mensagem": "Salty", "autor": "Jealous or bitter"},
    {"mensagem": "Cool", "autor": "Great"},
    {"mensagem": "Snatched", "autor": "Took or nice"},
    {"mensagem": "Yeet!", "autor": "Expression of excitement"},
    {"mensagem": "Yikes!", "autor": "No way! It cant be!"},
    {"mensagem": "Finsta", "autor": "Fake/Private Instagram account"},
    {"mensagem": "Periodt.", "autor": "End of discussion (for example, what a parent would say to the child)"},
    {"mensagem": "Flex", "autor": "Show off"},
    {"mensagem": "Low-key", "autor": "Modest, something you dont want emphasized"},
    {"mensagem": "Slay", "autor": "Excel at, win at or sexually active"},
    {"mensagem": "Gucci", "autor": "Trendy"},
    {"mensagem": "Shook", "autor": "Emotionally bothered/shaken"},
    {"mensagem": "Curve", "autor": "Rejecting someones advances"},
    {"mensagem": "Stan", "autor": "Obsessive fan"},
    {"mensagem": "Sus", "autor": "Suspect or suspicious"},
    {"mensagem": "Turnt", "autor": "Intoxicated or energized"},
    {"mensagem": "Bae", "autor": "A best friend or loved one"},
    {"mensagem": "Fleek", "autor": "Really good, stylish"},
    {"mensagem": "Cheugy", "autor": "Out of style"},
    {"mensagem": "Emo", "autor": "Overly angsty/emotional"},
    {"mensagem": "E-Girl/E-Boy", "autor": "An emo girl/boy"},
    {"mensagem": "Fam", "autor": "Family"},
    {"mensagem": "Smol", "autor": "Small/cute"},
    {"mensagem": "Snack", "autor": "Someone who is attractive"},
    {"mensagem": "Wig", "autor": "That is so cool!"},
    {"mensagem": "Lewk", "autor": "Someones personal style"},
    {"mensagem": "Hot", "autor": "Attractive"},
    {"mensagem": "Sick", "autor": "Nice or great"},
    {"mensagem": "Shady", "autor": "Suspicious"},
    {"mensagem": "Bomb", "autor": "Really good"},
    {"mensagem": "Boujee", "autor": "Fancy and extravagant"},
    {"mensagem": "Dank", "autor": "Excellent"},
    {"mensagem": "Flakey", "autor": "Indecisive"},
    {"mensagem": "Finna", "autor": "I am going to"},
    {"mensagem": "Ghost", "autor": "Suddenly start ignoring"},
    {"mensagem": "Hypebeast", "autor": "Someone who only wants to be popular"},
    {"mensagem": "W", "autor": "A win"},
    {"mensagem": "L", "autor": "A loss"},
    {"mensagem": "Whip", "autor": "Car"},
    {"mensagem": "Hip", "autor": "Popular, trendy"},
    {"mensagem": "Lemon", "autor": "Bad person"},
    {"mensagem": "Babe", "autor": "Attractive woman, your significant other"},
    {"mensagem": "Grub", "autor": "Food"},
    {"mensagem": "Jacked", "autor": "Muscular"},
    {"mensagem": "Chicken", "autor": "Coward"},
    {"mensagem": "Ex", "autor": "Former girlfriend or boyfriend"},
    {"mensagem": "Whiz", "autor": "A smart person"},
    {"mensagem": "Dump", "autor": "End a relationship"},
    {"mensagem": "Jonesing (For)", "autor": "Really want something"},
    {"mensagem": "Wack", "autor": "Bad, not good"},
    {"mensagem": "Score", "autor": "Get what you want"},
    {"mensagem": "Corny", "autor": "(Things like movies and jokes) silly"},
    {"mensagem": "Cheesy", "autor": "(Things like movies and jokes) silly"},
    {"mensagem": "Flick", "autor": "Movie"},
    {"mensagem": "Bummer", "autor": "Disappointment"},
    {"mensagem": "Wasted", "autor": "Intoxicated"},
    {"mensagem": "Booze", "autor": "Alcohol"},
    {"mensagem": "Yas!", "autor": "(Celebratory) yes!"},
    {"mensagem": "In the bag", "autor": "A certainty (usually for something that you want, like in sports or a contest)"},
    {"mensagem": "Once in a blue moon", "autor": "Rarely"},
    {"mensagem": "You bet", "autor": "Youre welcome"},
    {"mensagem": "I dont buy that", "autor": "I dont believe you"},
    {"mensagem": "Thats rad", "autor": "Thats cool"},
    {"mensagem": "Never mind", "autor": "Lets change the subject"},
    {"mensagem": "Keep in touch", "autor": "(When leaving someone) Lets stay in contact"},
    {"mensagem": "I cant even!", "autor": "I cant tolerate that anymore!"},
    {"mensagem": "No biggie", "autor": "Not a problem"},
    {"mensagem": "No big deal", "autor": "Not a problem"},
    {"mensagem": "No sweat", "autor": "Not a problem"},
    {"mensagem": "Show up", "autor": "Go to, do better than someone"},
    {"mensagem": "Have a crush (on someone)", "autor": "Like someone, want to ask someone out on a date"},
    {"mensagem": "Get hitched", "autor": "Get married"},
    {"mensagem": "Tie the knot", "autor": "Get married"},
    {"mensagem": "Im beat", "autor": "Im tired"},
    {"mensagem": "Get under ones skin", "autor": "Annoy"},
    {"mensagem": "A turn off", "autor": "Something you dont like about someone/somewhere"},
    {"mensagem": "Couch potato", "autor": "Someone whos always watching tv"},
    {"mensagem": "Party animal", "autor": "Someone who is always partying"},
    {"mensagem": "Hang out", "autor": "Spend time with people"},
    {"mensagem": "It sucked", "autor": "It was bad"},
    {"mensagem": "Im game", "autor": "I can join you/I will do it"},
    {"mensagem": "Im down", "autor": "I can join you/I will do it"},
    {"mensagem": "Im in", "autor": "I can join you/I will do it"},
    {"mensagem": "Have a blast", "autor": "Having a great time"},
    {"mensagem": "Get fired", "autor": "Lose ones job"},
    {"mensagem": "Hit the books", "autor": "Study"},
    {"mensagem": "Rip (someone) off", "autor": "Overcharge"},
    {"mensagem": "Ride shotgun", "autor": "Sit in the front passenger seat"},
    {"mensagem": "The bomb", "autor": "Excellent"},
    {"mensagem": "Have dibs on", "autor": "Make a claim on/to"},
    {"mensagem": "Suck up", "autor": "Win someones approval by being servile"},
    {"mensagem": "Kiss ass", "autor": "Win someones approval by being servile"},
    {"mensagem": "Fresh meat", "autor": "New target for humiliation"},
    {"mensagem": "Loose cannon", "autor": "Someone dangerously uncontrollable"},
    {"mensagem": "Pass the buck", "autor": "Make someone else responsible"},
    {"mensagem": "My bad", "autor": "My mistake"},
    {"mensagem": "Take for granted", "autor": "Assume"},
    {"mensagem": "Hit the road", "autor": "Leave (to go somewhere)"},
    {"mensagem": "Go dutch", "autor": "Every person pays for their own food/drinks"},
    {"mensagem": "Hit the spot", "autor": "(Said after food/drink) Really satisfying"},
    {"mensagem": "Wrap up", "autor": "Finish doing something"},
    {"mensagem": "Are you kidding me?", "autor": "Phrase to represent excitement/frustration"},
    {"mensagem": "Around the clock", "autor": "All the time, 24 hours a day, 7 seven days a week"},
    {"mensagem": "Take a rain check", "autor": "Do at a later time"},
    {"mensagem": "Whats up?", "autor": "How are you? Hows it going?"},
    {"mensagem": "Youre telling me!", "autor": "I know!"},
    {"mensagem": "All-ears", "autor": "Has your undivided attention"},
    {"mensagem": "All-nighter", "autor": "Staying up all night to do homework/chat with friends"},
    {"mensagem": "Im baby", "autor": "Im innocent/cute"},
    {"mensagem": "Glow up", "autor": "A makeover (transformed from ugly to less ugly)"},
    {"mensagem": "Open up pandoras box", "autor": "Cause/start trouble/problems"},
    {"mensagem": "In the zone", "autor": "Performing as well as or better than one can"},
    {"mensagem": "Tune out", "autor": "Stop paying attention"},
    {"mensagem": "Armchair CEO", "autor": "Offering expertise on something in which the speaker lacks"},
    {"mensagem": "Off the hook", "autor": "Freed from blame"},
    {"mensagem": "Off the chain", "autor": "Really good"},
    {"mensagem": "Bye, Felicia", "autor": "Said when youre done with a conversation."},
    {"mensagem": "(To) ace", "autor": "Excel at (a test)"},
    {"mensagem": "(To) nail", "autor": "Excel at (a test, a race)"},
    {"mensagem": "(To) screw over", "autor": "Cheat or exploit someone"},
    {"mensagem": "Amped up", "autor": "Filled with enthusiasm/energy"},
    {"mensagem": "On point", "autor": "Relevant and appropriate, really good"},
    {"mensagem": "No cap", "autor": "Not lying"},
    {"mensagem": "Straight fire", "autor": "Trendy"},
    {"mensagem": "Spill the tea/spill the beans", "autor": "Share the gossip/secret"},
    {"mensagem": "Netflix and chill", "autor": "Watch Netflix and have sex"},
    {"mensagem": "Big mad", "autor": "Really angry"},
    {"mensagem": "Diamond hands", "autor": "Taking a major financial risk"},
    {"mensagem": "Fauci ouchie", "autor": "COVID-19 vaccine"},
    {"mensagem": "Cancel culture", "autor": "Online shaming/embarrassment of someone due to their views"}
    
]